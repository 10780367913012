/**
 * Menu
 */

.menu {
  @include anim();
  position: relative;
  display: block;
  width: 100%;
  list-style: none;
  padding: 0 .8rem;
  margin: 0;
  font-family: $font-primary;
  font-weight: 300;

  &[aria-expanded="true"] {
    visibility: visible;
    max-height: 80vh;
    padding: 1rem .8rem .8rem;
    border: 1px solid $color-grey;
  }

  @include mq($until: $navCollapseOn) {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    border: 1px solid transparent;
  }
}

.menu--main {
  @include mq($until: $navCollapseOn) {
    width: 100%;
    &[aria-expanded="true"] {
      margin: 2rem 0;
      overflow-y: auto;
      background-color: #fff;
    }
  }

  @include mq($from: $navNarrowFrom, $until: $navCollapseOn) {
    position: absolute;
    max-width: 250px;
    top: 6rem;
    right: 2.2rem;
  }

  @include mq($from: $navCollapseOn) {
    width: initial;
    padding: 0;
  }
}

.menu__item {
  display: block;
  position: relative;
  line-height: 1;

  a {
    @include anim();
    color: $color-blue;
    display: block;
    padding: .8rem;
  }

  &.current-menu-item {
    > a {
      text-decoration: none;
    }
  }

  + .menu__item {
    margin-top: 0;
  }

  @include mq($from: $navCollapseOn) {

    .menu--main > & {
      display: block;
      padding: 0;
      line-height: 1.11111111;

      a {
        display: inline-block;
      }
    }

    &:last-child {
      a {
        padding-right: 0;
      }
    }
  }
}

.menu--sub {
  @include anim();
  border: none;
  padding: 0 2rem 0;

  &[aria-expanded="true"] {
    border: none;
    padding: 0 2rem .8rem;
  }

  @include mq($from: $navCollapseOn) {
    transform: translateY(-10%);
    visibility: hidden;
    opacity: 0;
    max-height: 1px;
    overflow: hidden;
    display: block;
    padding: 0 1rem 0;

    .menu__item {
      float: none;
      display: block;
      margin: 0 0 0 2rem;

      a {
        display: inline-block;
      }
    }

    &[aria-expanded="true"],
    .current-menu-parent &,
    .current-menu-item.menu-item-has-children & {
      padding: 0 1rem 0;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      max-height: 80vh
    }
  }
}

/**
 * Menu Bar
 */

.navigation > button {
  @include anim();
  top: 2.2rem;
  position: absolute;
  padding: 0;
  right: 0;
  width: 2.4rem;
  height: 2.4rem;
  background-color: transparent;
  border: none;


  @include mq($from: $navCollapseOn) {
    display: none;
  }

  &:hover {
    background-color: transparent;
  }
}

.menu-bar {
  @include anim();
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  margin: 6px auto;
}

button[aria-expanded="true"] {
  .menu-bar {
    transform-origin: 0 100%;

    &:first-child {
      transform: rotate(45deg) translateY(-5px);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:last-child {
      transform: rotate(-45deg) translateY(2px) translateX(5px);
    }
  }
}
