/**
 * This file works as a manifest, where all @imports and initial settings are
 * defined.
 */

/* MQ SETTINGS */
$mq-responsive: true;
$mq-breakpoints: (
  mobile: 320px,
  mobileLandscape: 460px,
  tablet: 768px,
  desktop: 992px,
  wide: 1200px,
  xl: 1440px,
);

// bower:scss
@import 'bower_components/slick-carousel/slick/slick.scss';
@import 'bower_components/sass-mq/_mq.scss';
// endbower

// abstracts
@import "abstract/reset";
@import "abstract/variables";
@import "abstract/mixins";
@import "abstract/spacing";
@import "abstract/utilities";

// base
@import "base/global";
@import "base/typography";
@import "base/links";

// components
@import "component/image";
@import "component/table";
@import "component/slider";
@import "component/form";
@import "component/article";
@import "component/box";
@import "component/gallery";
@import "component/pagination";

// layouts
@import "layout/header";
@import "layout/menu";

