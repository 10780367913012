/**
 * _box
*/

$profiles: brinkhoff 5 10, west-leuer 20 11, supervision 35 11, kubach 32 33, vehreschild 17 47, tress 13 23, vogel -4 43.5, tangen-petraitis -16 43, epc -22 33, offermanns -5 20, brouwers -20 22, loewer-hirsch -11 6, pop 35 -6, ippp 20 -6, doerte 5 -.5, henning -15 -3, urban -27 47, dauth 43 29;

@include mq($from: desktop) {
  @each $profile, $left, $top in $profiles {
    .box--#{$profile} {
      left: $left + rem;
      top: $top + rem;
    }
  }
}

@include mq($from: wide) {
  $profiles: brinkhoff 27 12, west-leuer 40 14, supervision 60 14, kubach 47 38, vehreschild 16 47, tress 24 30, vogel 1 48, tangen-petraitis -10 37, epc 12 9, offermanns 10 23, brouwers -11 23, loewer-hirsch -8 -1, pop 54 -1, ippp 38 -5, doerte 22 -1, henning 5 -3, urban -20 48, dauth 61 31;
  @each $profile, $left, $top in $profiles {
    .box--#{$profile} {
      left: $left + rem;
      top: $top + rem;
    }
  }
}


.box {
  @include clearfix();
  width: 100%;
  border: 1px solid #ccc;
  border-top: .4rem solid $color-blue;
  padding: 1.5rem 1rem 1.3rem;

  @include mq($from: desktop) {
    text-align: center;
    border: none;
    max-width: 14rem;
    position: absolute;

    &:hover {
      z-index: 99;
    }
  }
}

.box__title {
  font-family: $font-primary;
  font-weight: bold;
}

.box__image {
  float: left;
  background: transparent no-repeat 50% 50% / cover;
  height: 5rem;
  width: 5rem;

  .box--rounded & {
    border-radius: 50%;
    height: 6.5rem;
    width: 6.5rem;
  }

  @include mq($from: desktop) {
      display: block;
      margin: 0 auto;
  }
}

.box__content {
  margin-top: 0;
  float: left;
  margin-left: 2rem;
  width: calc(100% - 6.5rem - 2rem);

  @include mq($from: desktop) {
    width: 100%;
    margin-left: 0;
    margin-top: .8rem;
    font-size: 1.2rem;
  }
}

.box__image,
.box__content {
  position: relative;

  @include mq($from: desktop) {
      float: none;
  }
}
