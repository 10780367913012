/**
 * Links
 */

a {
  color: $color-blue;

  &[href^='http'] {
    &:after {
      $iconSize: 1rem;
      @include anim();
      @include pseudo($pos: relative, $display: inline-block);
      opacity: .5;
      top: -.3rem;
      height: $iconSize;
      width: $iconSize;
      background: transparent no-repeat 50% 50% / #{$iconSize};
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M5 3c-1.102 0-2 .898-2 2v14c0 1.102.898 2 2 2h14c1.102 0 2-.898 2-2v-6l-2-2v8H5V5h8l-2-2zm9 0l2.656 2.656-7.5 7.5 1.688 1.688 7.5-7.5L21 10V3z'/%3E%3C/svg%3E");
    }

    &.no-external,
    aside & {
      &:after {
        display: none;
      }
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  &:hover {
    text-decoration: none;
  }

  .read-more &,
  .menu__item & {
    text-decoration: none;
  }

  &[data-lightbox] {
    display: block;
  }
}

button,
.button {
  cursor: pointer;
  @include anim();
  background-color: transparent;
  border: 1px solid $color-blue;
  border-radius: 0;
  padding: .5rem 1.6rem;
  font-size: 1.6rem;
  line-height: 2.3rem;
  width: 100%;
  display: block;
  max-width: 30rem;

  svg {
    width: 100%;
    height: 100%;
  }

  &:visited {
    color: #000;
  }

  &:hover {
    background-color: $color-blue;
    color: #fff;
  }

  &:active {
    background-color: darken($color-blue, 5%);
  }

}

.button--dropdown-trigger {
  @include anim();
  background-color: transparent;
  padding: .4rem .7rem .5rem;
  /* border: 1px solid $color-grey; */
  border: none;
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 0;
  right: .8rem;
  z-index: 10;
  border-radius: 0;

  svg {
    transform-origin: center center;
    @include anim();
    stroke: $var-color;
  }

  &[aria-expanded="true"] {
    @include mq($until: wide) {
      padding-top: .3rem;
    }
    svg {
      transform: rotateZ(180deg);
    }
  }

  &:focus {
    outline: none;
  }

  @include mq($from: $navCollapseOn) {
    position: relative;
    right: initial;
    top: .6rem;
    border: none;
    width: 1rem;
    height: 2rem;
    padding: 0;
    display: inline;

    &[aria-expanded="true"] {
      background-color: transparent;
    }
  }

  &:hover {
    background-color: transparent;
  }
}

.button--download {
  position: relative;

  &:before,
  &:after {
    @include pseudo();
    @include anim();
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent no-repeat 1rem 50% / 2.4rem 2.4rem;
  }

  &:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M15 4v16.563L9.72 15.28l-1.44 1.44 7 7 .72.686.72-.687 7-7-1.44-1.44L17 20.564V4zM7 26v2h18v-2z'/%3E%3C/svg%3E");
  }

  &:after {
    opacity: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M15 4v16.563L9.72 15.28l-1.44 1.44 7 7 .72.686.72-.687 7-7-1.44-1.44L17 20.564V4zM7 26v2h18v-2z' fill='%23ffffff'/%3E%3C/svg%3E");
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}
