/**
 * Header
 */

header {
  padding: 2rem 1.5rem 0;
  display: block;
  position: relative;

  @include mq($from: $navCollapseOn) {
    margin-top: 1rem;
    padding: 0 0 0 5.5rem;
  }
}

.navigation {
  position: relative;
  display: block;
  max-width: 100%;
  @include clearfix();
}

.logo {
  @include clearfix();
  display: block;
  width: 10rem;
  margin: 0 auto;
  text-decoration: none;
  text-align: center;

  svg {
    display: block;
    width: 6.4rem;
    height: 8rem;
    margin: 0 auto;
    stroke: $var-color;
  }

  @include mq($from: $navCollapseOn) {
    position: relative;
    width: 15rem;
    margin: 0;
    left: calc(38% + 2rem);

    svg {
      width: 10rem;
      height: 12.5rem;
    }
  }

  @include mq($from: wide) {
    left: calc(31% + 2rem);
  }
}

.logo__text {
  color: $var-color;
  text-decoration: none;
  font-family: $font-primary;
  font-weight: 300;

  &:first-of-type {
    font-family: $font-book;
  }

  @include mq($from: $navCollapseOn) {
      font-size: 2.2rem;
  }
}
