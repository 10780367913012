/**
 * Variables
 */

$color-lime: #bdd12c;
$color-green: #2b9e47;
$color-violet: #771c88;
$color-redViolet: #BA136C;
$color-petrol: #7AB8CD;
$color-orange: #DD9821;
$color-blue: #222b5a;
$color-default: #033c57;

$color-grey: #ccc;

$navCollapseOn: tablet; // small, tablet, desktop, wide or xl
$navNarrowFrom: tablet;

$font-book: 'Archer Book';
$font-primary: 'Archer';

$contentWidth: 62%;

$anim: .25s cubic-bezier(.5, .9, 0, 1);

$var-color: $color-blue!default;

$colorlist: (
  lime #bdd12c -1.5rem,
  green #2b9e47 0,
  violet #771c88 -4.8rem,
  redViolet #BA136C 0,
  petrol #7AB8CD -3rem,
  orange #DD9821 -2rem,
  blue #222b5a 0
);

@each $color, $hexcode, $bgPosY in $colorlist {

  .body--color-#{$color} {
    $var-color: $hexcode;

    // Keep wave line with matching color
    /*.wave__line:not(.wave__line--#{$color}) {
      display: none;
    }*/

    .waves {
      background-image: url('/assets/images/line-#{$color}.svg');
      background-position-y: $bgPosY;
    }

    .logo svg:not(.locked) {
      stroke: $var-color;
    }

    // Table
    table tbody tr:nth-of-type(odd) {
      background-color: lighten($var-color, 20%);
    }

    table tbody tr:nth-of-type(even) {
      background-color: lighten($var-color, 30%);
    }

    table thead tr {
      background-color: lighten($var-color, 40%);
    }

    // Links & Buttons
    a:hover {
      color: $var-color;
    }

    article a,
    .current-menu-item > a {
      color: $var-color;
    }

    button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger),
    .button:not(.button--no-color) {

      border: 1px solid $var-color;
      &:hover {
        background-color: $var-color;
      }

      &:active {
        background-color: darken($var-color, 5%);
      }

      @include mq($from: $navCollapseOn) {
          &.control:nth-of-type(1n+2) {
            border-left: none;
          }
      }
    }

    .current-menu-item .button--dropdown-trigger,
    .current_page_item .button--dropdown-trigger {
      svg {
        stroke: $var-color;
      }
    }

    .table__heading:not(.table__heading--sub) {
      @include mq($from: $navCollapseOn) {
          button {
            border-top: none;
          }
      }
    }

    .mixitup-control-active {
      background-color: $var-color;
    }

    .pagination .page-numbers {
      background-color: $var-color;

      &:hover,
      &.current {
        background-color: lighten($var-color, 15%);
      }
    }

    .table--tabbed .container {
      border-color: $var-color;
    }

    // Slider

    .slick-dots button {
      background-color: $var-color;

      &:hover {
        background-color: lighten($var-color, 40%);
      }
    }

    // Form
    label {
      color: $var-color;
    }

    input, textarea {
      border: 1px solid $var-color;
    }

    .article-teaser {
      border-bottom: 1px solid $var-color;
    }

    h1, .h1, h2, .h2, h3, .h3 {
      color: $var-color;
    }

    // Box
    .box {
      border-top-color: $var-color;
    }
  }
}

/**
 * Placeholder
 */

%shadow {
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
