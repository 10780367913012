/**
 * Typography
 */

@font-face {
  font-family: 'Archer Book';
  src: url('../fonts/Archer-Book.eot');
  src: url('../fonts/Archer-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Archer-Book.woff') format('woff'),
  url('../fonts/Archer-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Archer';
  src: url('../fonts/Archer-Light.eot');
  src: url('../fonts/Archer-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Archer-Light.woff') format('woff'),
  url('../fonts/Archer-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Archer';
  src: url('../fonts/Archer-Bold.eot');
  src: url('../fonts/Archer-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Archer-Bold.woff') format('woff'),
  url('../fonts/Archer-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

h1, .h1, h2, .h2, h3, .h3 {
  color: $color-blue;
  font-family: $font-primary;
  font-weight: bold;
}

h1, .h1 {
  font-size: 2.4rem;
}
h2, .h2 {
  font-size: 2rem;
}
h3, .h3 {
  font-size: 1.6rem;
}

article * + * {
  margin-top: 1.2rem;

  @include mq($from: desktop) {
    margin-top: 2rem;
  }
}

ul {
  list-style: circle;
  padding-left: 2rem
}

li {

  + li {
    margin-top: .2rem;
  }

  ul {
    padding-left: 3rem;
    margin-top: .8rem;
  }
}

b, strong {
  font-family: $font-primary;
  font-weight: bold;
}

em {
  font-style: italic;
}
