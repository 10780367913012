/**
 * _gallery
*/

.gallery {
  @include clearfix();
  @include mq($from: tablet) {
      margin-left: -.2rem;
      margin-right: -.2rem;
  }
}

.gallery__item {
  float: left;
  width: calc(50% - .5rem);
  margin-right: .5rem;

  + .gallery__item {
    margin-top: 0;
  }

  @include mq($until: tablet) {
    &:nth-child(2n+2) {
      margin-left: .5rem;
      margin-right: 0;
    }
    &:nth-child(1n+3) {
      margin-top: 1rem;
    }
  }
  @include mq($from: tablet) {
    $third: percentage(1/3);
    width: calc(#{$third} - .4rem);
    margin: 0 .2rem;

    &:nth-child(1n+4) {
      margin-top: .4rem;
    }
    /*&:nth-child(1n+2) {
      margin-left: .4rem;
    }
    &:nth-child(3n+1) {
      margin-left: 0;
    }
    &:nth-child(1n+4) {
      margin-top: .4rem;
    }*/
  }
}
