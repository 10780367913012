/**
 * _image
*/

.image {
  max-width: 100%;
  height: auto;
}

.image--portrait {
  @include mq($from: tablet) {
    max-width: 30rem;
  }
}

.image--full {
  width: 100%;
  height: auto;
}

.image--thumbnail {
  width: 100%;
  height: 20rem;
  background: transparent no-repeat 50% 50% / cover;
  @include mq($from: tablet) {
    height: 15rem;
    width: 15rem;
  }

  .gallery & {
    width: 100%;
    height: auto;
  }
}
