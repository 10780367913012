/**
 * _slider
*/

.slider {
  overflow: hidden;
}

.slick-slide {
  + .slick-slide {
    margin-top: 0;
  }
}

.slider--gallery {
  .image--thumbnail {
    display: block;
    width: 100%;
    height: auto;

    @extend .embed--1by1;
    &:before {
      content: '';
      display: block;
    }
  }

  .slick-slide {
    margin: 0 .2rem;
  }

  .slick-list {
    margin: 0 -.2rem;
  }
}


/**
 * Slider Controls
 */

.slider__arrow {
  position: absolute;
  left: -1.2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  height: 100%;
  padding: 0;
  border: none;
  z-index: 10;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.42 75'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %233a3a3a; %7D %3C/style%3E%3C/defs%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cpath id='Ebene_1-2' d='M37.5 0L0 37.5 37.5 75h19.92l-37.5-37.5L57.42 0H37.5z' class='cls-1' data-name='Ebene 1'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 center / 2.4rem 3.2rem;
  opacity: 0;
  transition: $anim;
  margin-top: 0;

  .slider--default & {
    top: 40%;
    height: 95%;

    @include mq($from: tablet) {
        top: 45%;
    }
  }

  .slider--gallery & {
    width: 2.4rem;
  }

  &:focus {
    outline: none;
  }

  .slider:hover & {
    opacity: .7;
    left: 3rem;

    &:hover {
      opacity: 1;
      background-color: transparent;
    }
  }

  .slider--article & {
    height: 100%;
    width: 50%;
    background-position-x: 3rem;

    &--next {
      background-position-x: calc(100% - 3rem);
    }
  }

  .slider--article:hover & {
    left: 0;
  }

  &--next {
    left: auto;
    right: -2.4rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.42 75'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %233a3a3a; %7D %3C/style%3E%3C/defs%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cpath id='Ebene_1-2' d='M19.92 0l37.5 37.5L19.92 75H0l37.5-37.5L0 0h19.92z' class='cls-1' data-name='Ebene 1'/%3E%3C/g%3E%3C/svg%3E");
    background-position-x: 100%;

    .slider:hover & {
      left: auto;
      right: 3rem;
    }

    .slider--article:hover & {
      right: 0;
      left: auto
    }
  }
}

.slick-lightbox .slick-arrow {
  @extend .slider__arrow;
  z-index: 999999999;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;
  background-color: #fff;
  background-size: 50%;
  background-position-x: 45%;
  left: 1.5rem;
}

.slick-lightbox .slick-next {
  @extend .slider__arrow--next;
  right: 1.5rem;
  left: initial;
  background-position-x: 55%;
}

.slider__arrow {
  @include mq($until: tablet) {
    opacity: 1;
    left: 3rem;

    &--next {
      right: 3rem;
      left: auto;
    }
  }
}

.slick-dots {
  padding: 0;
  margin: 0 auto;
  z-index: 10;
  list-style: none;
  text-align: center;
  @include clearfix();

  @include mq($until: tablet) {
    background-color: #fff;
    margin-bottom: 2rem;

    .slider--with-caption & {
      position: absolute;
      margin-bottom: 0;
      bottom: 0;
      background-color: transparent;
      right: 0;
      left: 0;
    }
  }

  li {
    vertical-align: middle;
    display: inline-block;
    margin-right: 1rem;
    width: 8px;
    height: 8px;

    + li {
      margin-top: 0;
    }
  }

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 100%;
    border: none;
    outline: none;
    background-color: $color-blue;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    transform: scale(.5);
    transform-origin: 50% 50%;
    transition: $anim;

    &:hover {
      background-color: lighten($color-blue, 40%);
      transform: scale(1);
    }
  }
  .slick-active button {
    transform: scale(1);
  }

  .slider--testimonials & {
    position: relative;
    bottom: initial;
  }
}
