/**
 * _article
*/

article {
  @include clearfix();
}

.article-teaser {
  @include clearfix();
  border-bottom: 1px solid $color-blue;
  padding-bottom: 2rem;

  + .article-teaser {
    margin-top: 3rem;
  }

  @include mq($from: $navCollapseOn) {
    margin-top: 6rem;
  }
}

.read-more {
  margin-left: 0.1rem;
}

.article-teaser__image {
  @include mq($from: tablet) {
      float: right;
  }

  + .article-teaser__content {
    margin-top: 0;
  }
}

.article-teaser__content {
  @include mq($from: tablet) {
    float: left;
    width: calc(100% - 18rem);
  }

  h3 {
    @include mq($until: tablet) {
      margin-top: 1.2rem;
    }
  }
}
