/**
 * _table
*/

table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}

table thead th {
  text-align: left;
  vertical-align: bottom;
}

table td,
table th {
  padding: .75em;
}

table tbody tr:nth-of-type(odd) {
  background-color: lighten($color-blue, 60%);
}

table tbody tr:nth-of-type(even) {
  background-color: lighten($color-blue, 70%);
}

table thead tr {
  background-color: lighten($color-blue, 50%);
}

.table--comparison {
  tr {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }

  td, th {
    display: block;
    width: percentage(1/3);

    &:first-child {
      width: 100%;
    }
  }
  th:first-child {
    display: none;
  }
}





/**
 * MixItUp Styles for Tabbed Table
 */

.controls {
  @include mq($from: $navCollapseOn) {
    display: flex;
    flex-wrap: wrap;
  }
}

.controls__group {
  @include clearfix();
  overflow: hidden;

  button {
    max-width: none;
    width: 100%;
    position: relative;

    &:focus {
      outline: none;
    }
  }

  > button {
    position: relative;

    svg {
      position: absolute;
      top: .8rem;
      right: 1rem;
      margin-top: 0;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  @include mq($from: $navCollapseOn) {
    > button {
      display: none;
    }

    flex: 1 100%;
    &:first-child {
      order: 2;
    }
  }
}

.table__heading {
  @include anim();
  position: relative;
  max-height: 0;

  &--is-visible {
    max-height: 500px;
  }

  @include mq($from: $navCollapseOn) {
    @include clearfix();
    max-height: initial;
    margin-top: 0;

    button {
      margin-top: 0;
      float: left;
      pointer-events: none;
      border-top: none;
      width: percentage(1/3);
      &:hover {
        cursor: not-allowed;
      }

      &:nth-of-type(1n+2) {
        border-left: none;
      }
    }

  }
}

.mixitup-control-active {
  background-color: $color-blue;
  color: #fff;
}

.table__heading--sub {
  @include mq($from: $navCollapseOn) {
      button {
        border-top: 1px solid $color-blue;
        pointer-events: all;
        width: percentage(1/2);
        &:hover {
          cursor: pointer;
        }
      }
  }
}

.table__content {
  @include mq($from: $navCollapseOn) {
    margin-top: 0;
    width: percentage(1/3);
    float: left;
  }
}
