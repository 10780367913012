/**
 * _pagination
*/

.pagination {
  margin-top: 4.8rem;
  @include clearfix();
  width: 100%;
  text-align: right;

  .page-numbers {
    margin-top: 0;
    padding: .8rem 1.2rem;
    color: #fff;
    text-decoration: none;
    background-color: $var-color;

    + .page-numbers {
      margin-top: 0;
      margin-left: .4rem;
    }

    &:hover,
    &.current {
      background-color: lighten($var-color, 15%);
    }
  }
}
