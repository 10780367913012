/**
 * Global
 */

html {
  display: block;
  height: 100%;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  display: block;
  position: relative;
  min-height: 100%;
  font: normal 1.6rem/1.4375 $font-book, sans-serif;
  color: $color-default;
}

.row {
  position: relative;
}

.waves {
  position: relative;
  height: 15rem;
  background: transparent url(/assets/images/lines-all.svg) repeat-x -15.5rem 0 / cover;

  @include mq($from: $navCollapseOn) {
    height: 17rem;
  }
}

.waves__link {
  position: absolute;
  left: 5.5rem + 2.3rem;
  bottom: 4.5rem - 2.5rem;
  text-decoration: none;

  @include mq($from: $navCollapseOn) {
      bottom: 5rem - 2.5rem;
  }
}

.page-wrap {
  position: relative;
}

.page-wrap__main,
header {
  @include clearfix();
  max-width: 1200px;
  @include mq($from: $navCollapseOn) {
    padding-left: 5.5rem;
  }
}

.page-wrap__main--netzwerk {
  @include mq($from: desktop) {
    background: transparent url(/assets/images/lines.svg) repeat-x;
  }
}

aside {
  padding: 0 1.5rem;
  @include mq($from: $navCollapseOn) {
    width: calc(100% - #{$contentWidth});
    float: left;
    padding-right: 4rem;
  }

  @include mq($from: wide) {
    $contentWidth: 69%;
    width: calc(100% - #{$contentWidth});
  }
}

.lines {
  display: none;
  @include mq($from: desktop) {
    z-index: -1;
    display: block;
    position: absolute;
    top: 26rem;
    left: 0;
    height: 80rem;
    width: 100%;
    background: url(/assets/images/lines.svg) repeat-x -30rem 2rem / auto 60rem;
  }
}

main {
  @extend .bottomSpacing1;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @include mq($from: $navCollapseOn) {
    padding-left: 4rem;
    width: $contentWidth;
    float: left;
    max-width: 60rem;
  }

  @include mq($from: wide) {
    $contentWidth: 69%;
    width: $contentWidth;
  }
}

.container {
  @include clearfix();
  overflow: hidden;

  .table--tabbed & {
    border: 1px solid $color-blue;
    border-top: none;
    margin-top: 0;
    padding: 2.4rem 0 2rem;
  }
}
