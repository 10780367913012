/**
 * This file works as a manifest, where all @imports and initial settings are
 * defined.
 */
/* MQ SETTINGS */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**
 * Reset
 */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box;
  text-transform: lowercase;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

svg:not('.locked') path,
svg:not('.locked') g {
  fill: inherit;
  stroke: inherit;
}

/**
 * Variables
 */
.body--color-lime {
  /*.wave__line:not(.wave__line--lime) {
      display: none;
    }*/
}

.body--color-lime .waves {
  background-image: url("/assets/images/line-lime.svg");
  background-position-y: -1.5rem;
}

.body--color-lime .logo svg:not(.locked) {
  stroke: #bdd12c;
}

.body--color-lime table tbody tr:nth-of-type(odd) {
  background-color: #d8e47f;
}

.body--color-lime table tbody tr:nth-of-type(even) {
  background-color: #e5eda9;
}

.body--color-lime table thead tr {
  background-color: #f2f6d3;
}

.body--color-lime a:hover {
  color: #bdd12c;
}

.body--color-lime article a,
.body--color-lime .current-menu-item > a {
  color: #bdd12c;
}

.body--color-lime button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger),
.body--color-lime .button:not(.button--no-color) {
  border: 1px solid #bdd12c;
}

.body--color-lime button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):hover,
.body--color-lime .button:not(.button--no-color):hover {
  background-color: #bdd12c;
}

.body--color-lime button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):active,
.body--color-lime .button:not(.button--no-color):active {
  background-color: #aabc28;
}

@media (min-width: 48em) {
  .body--color-lime button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger).control:nth-of-type(1n+2),
  .body--color-lime .button:not(.button--no-color).control:nth-of-type(1n+2) {
    border-left: none;
  }
}

.body--color-lime .current-menu-item .button--dropdown-trigger svg,
.body--color-lime .current_page_item .button--dropdown-trigger svg {
  stroke: #bdd12c;
}

@media (min-width: 48em) {
  .body--color-lime .table__heading:not(.table__heading--sub) button {
    border-top: none;
  }
}

.body--color-lime .mixitup-control-active {
  background-color: #bdd12c;
}

.body--color-lime .pagination .page-numbers {
  background-color: #bdd12c;
}

.body--color-lime .pagination .page-numbers:hover, .body--color-lime .pagination .page-numbers.current {
  background-color: #d1e06a;
}

.body--color-lime .table--tabbed .container {
  border-color: #bdd12c;
}

.body--color-lime .slick-dots button {
  background-color: #bdd12c;
}

.body--color-lime .slick-dots button:hover {
  background-color: #f2f6d3;
}

.body--color-lime label {
  color: #bdd12c;
}

.body--color-lime input, .body--color-lime textarea {
  border: 1px solid #bdd12c;
}

.body--color-lime .article-teaser {
  border-bottom: 1px solid #bdd12c;
}

.body--color-lime h1, .body--color-lime .h1, .body--color-lime h2, .body--color-lime .h2, .body--color-lime h3, .body--color-lime .h3 {
  color: #bdd12c;
}

.body--color-lime .box {
  border-top-color: #bdd12c;
}

.body--color-green {
  /*.wave__line:not(.wave__line--green) {
      display: none;
    }*/
}

.body--color-green .waves {
  background-image: url("/assets/images/line-green.svg");
  background-position-y: 0;
}

.body--color-green .logo svg:not(.locked) {
  stroke: #2b9e47;
}

.body--color-green table tbody tr:nth-of-type(odd) {
  background-color: #5cd379;
}

.body--color-green table tbody tr:nth-of-type(even) {
  background-color: #84de9a;
}

.body--color-green table thead tr {
  background-color: #ace9bb;
}

.body--color-green a:hover {
  color: #2b9e47;
}

.body--color-green article a,
.body--color-green .current-menu-item > a {
  color: #2b9e47;
}

.body--color-green button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger),
.body--color-green .button:not(.button--no-color) {
  border: 1px solid #2b9e47;
}

.body--color-green button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):hover,
.body--color-green .button:not(.button--no-color):hover {
  background-color: #2b9e47;
}

.body--color-green button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):active,
.body--color-green .button:not(.button--no-color):active {
  background-color: #268a3e;
}

@media (min-width: 48em) {
  .body--color-green button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger).control:nth-of-type(1n+2),
  .body--color-green .button:not(.button--no-color).control:nth-of-type(1n+2) {
    border-left: none;
  }
}

.body--color-green .current-menu-item .button--dropdown-trigger svg,
.body--color-green .current_page_item .button--dropdown-trigger svg {
  stroke: #2b9e47;
}

@media (min-width: 48em) {
  .body--color-green .table__heading:not(.table__heading--sub) button {
    border-top: none;
  }
}

.body--color-green .mixitup-control-active {
  background-color: #2b9e47;
}

.body--color-green .pagination .page-numbers {
  background-color: #2b9e47;
}

.body--color-green .pagination .page-numbers:hover, .body--color-green .pagination .page-numbers.current {
  background-color: #48cd69;
}

.body--color-green .table--tabbed .container {
  border-color: #2b9e47;
}

.body--color-green .slick-dots button {
  background-color: #2b9e47;
}

.body--color-green .slick-dots button:hover {
  background-color: #ace9bb;
}

.body--color-green label {
  color: #2b9e47;
}

.body--color-green input, .body--color-green textarea {
  border: 1px solid #2b9e47;
}

.body--color-green .article-teaser {
  border-bottom: 1px solid #2b9e47;
}

.body--color-green h1, .body--color-green .h1, .body--color-green h2, .body--color-green .h2, .body--color-green h3, .body--color-green .h3 {
  color: #2b9e47;
}

.body--color-green .box {
  border-top-color: #2b9e47;
}

.body--color-violet {
  /*.wave__line:not(.wave__line--violet) {
      display: none;
    }*/
}

.body--color-violet .waves {
  background-image: url("/assets/images/line-violet.svg");
  background-position-y: -4.8rem;
}

.body--color-violet .logo svg:not(.locked) {
  stroke: #771c88;
}

.body--color-violet table tbody tr:nth-of-type(odd) {
  background-color: #bc35d5;
}

.body--color-violet table tbody tr:nth-of-type(even) {
  background-color: #ca5fde;
}

.body--color-violet table thead tr {
  background-color: #d889e7;
}

.body--color-violet a:hover {
  color: #771c88;
}

.body--color-violet article a,
.body--color-violet .current-menu-item > a {
  color: #771c88;
}

.body--color-violet button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger),
.body--color-violet .button:not(.button--no-color) {
  border: 1px solid #771c88;
}

.body--color-violet button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):hover,
.body--color-violet .button:not(.button--no-color):hover {
  background-color: #771c88;
}

.body--color-violet button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):active,
.body--color-violet .button:not(.button--no-color):active {
  background-color: #641873;
}

@media (min-width: 48em) {
  .body--color-violet button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger).control:nth-of-type(1n+2),
  .body--color-violet .button:not(.button--no-color).control:nth-of-type(1n+2) {
    border-left: none;
  }
}

.body--color-violet .current-menu-item .button--dropdown-trigger svg,
.body--color-violet .current_page_item .button--dropdown-trigger svg {
  stroke: #771c88;
}

@media (min-width: 48em) {
  .body--color-violet .table__heading:not(.table__heading--sub) button {
    border-top: none;
  }
}

.body--color-violet .mixitup-control-active {
  background-color: #771c88;
}

.body--color-violet .pagination .page-numbers {
  background-color: #771c88;
}

.body--color-violet .pagination .page-numbers:hover, .body--color-violet .pagination .page-numbers.current {
  background-color: #af29c7;
}

.body--color-violet .table--tabbed .container {
  border-color: #771c88;
}

.body--color-violet .slick-dots button {
  background-color: #771c88;
}

.body--color-violet .slick-dots button:hover {
  background-color: #d889e7;
}

.body--color-violet label {
  color: #771c88;
}

.body--color-violet input, .body--color-violet textarea {
  border: 1px solid #771c88;
}

.body--color-violet .article-teaser {
  border-bottom: 1px solid #771c88;
}

.body--color-violet h1, .body--color-violet .h1, .body--color-violet h2, .body--color-violet .h2, .body--color-violet h3, .body--color-violet .h3 {
  color: #771c88;
}

.body--color-violet .box {
  border-top-color: #771c88;
}

.body--color-redViolet {
  /*.wave__line:not(.wave__line--redViolet) {
      display: none;
    }*/
}

.body--color-redViolet .waves {
  background-image: url("/assets/images/line-redViolet.svg");
  background-position-y: 0;
}

.body--color-redViolet .logo svg:not(.locked) {
  stroke: #BA136C;
}

.body--color-redViolet table tbody tr:nth-of-type(odd) {
  background-color: #ec479f;
}

.body--color-redViolet table tbody tr:nth-of-type(even) {
  background-color: #f175b7;
}

.body--color-redViolet table thead tr {
  background-color: #f6a3cf;
}

.body--color-redViolet a:hover {
  color: #BA136C;
}

.body--color-redViolet article a,
.body--color-redViolet .current-menu-item > a {
  color: #BA136C;
}

.body--color-redViolet button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger),
.body--color-redViolet .button:not(.button--no-color) {
  border: 1px solid #BA136C;
}

.body--color-redViolet button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):hover,
.body--color-redViolet .button:not(.button--no-color):hover {
  background-color: #BA136C;
}

.body--color-redViolet button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):active,
.body--color-redViolet .button:not(.button--no-color):active {
  background-color: #a3115f;
}

@media (min-width: 48em) {
  .body--color-redViolet button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger).control:nth-of-type(1n+2),
  .body--color-redViolet .button:not(.button--no-color).control:nth-of-type(1n+2) {
    border-left: none;
  }
}

.body--color-redViolet .current-menu-item .button--dropdown-trigger svg,
.body--color-redViolet .current_page_item .button--dropdown-trigger svg {
  stroke: #BA136C;
}

@media (min-width: 48em) {
  .body--color-redViolet .table__heading:not(.table__heading--sub) button {
    border-top: none;
  }
}

.body--color-redViolet .mixitup-control-active {
  background-color: #BA136C;
}

.body--color-redViolet .pagination .page-numbers {
  background-color: #BA136C;
}

.body--color-redViolet .pagination .page-numbers:hover, .body--color-redViolet .pagination .page-numbers.current {
  background-color: #ea3093;
}

.body--color-redViolet .table--tabbed .container {
  border-color: #BA136C;
}

.body--color-redViolet .slick-dots button {
  background-color: #BA136C;
}

.body--color-redViolet .slick-dots button:hover {
  background-color: #f6a3cf;
}

.body--color-redViolet label {
  color: #BA136C;
}

.body--color-redViolet input, .body--color-redViolet textarea {
  border: 1px solid #BA136C;
}

.body--color-redViolet .article-teaser {
  border-bottom: 1px solid #BA136C;
}

.body--color-redViolet h1, .body--color-redViolet .h1, .body--color-redViolet h2, .body--color-redViolet .h2, .body--color-redViolet h3, .body--color-redViolet .h3 {
  color: #BA136C;
}

.body--color-redViolet .box {
  border-top-color: #BA136C;
}

.body--color-petrol {
  /*.wave__line:not(.wave__line--petrol) {
      display: none;
    }*/
}

.body--color-petrol .waves {
  background-image: url("/assets/images/line-petrol.svg");
  background-position-y: -3rem;
}

.body--color-petrol .logo svg:not(.locked) {
  stroke: #7AB8CD;
}

.body--color-petrol table tbody tr:nth-of-type(odd) {
  background-color: #c4e0e9;
}

.body--color-petrol table tbody tr:nth-of-type(even) {
  background-color: #e9f3f7;
}

.body--color-petrol table thead tr {
  background-color: white;
}

.body--color-petrol a:hover {
  color: #7AB8CD;
}

.body--color-petrol article a,
.body--color-petrol .current-menu-item > a {
  color: #7AB8CD;
}

.body--color-petrol button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger),
.body--color-petrol .button:not(.button--no-color) {
  border: 1px solid #7AB8CD;
}

.body--color-petrol button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):hover,
.body--color-petrol .button:not(.button--no-color):hover {
  background-color: #7AB8CD;
}

.body--color-petrol button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):active,
.body--color-petrol .button:not(.button--no-color):active {
  background-color: #67aec6;
}

@media (min-width: 48em) {
  .body--color-petrol button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger).control:nth-of-type(1n+2),
  .body--color-petrol .button:not(.button--no-color).control:nth-of-type(1n+2) {
    border-left: none;
  }
}

.body--color-petrol .current-menu-item .button--dropdown-trigger svg,
.body--color-petrol .current_page_item .button--dropdown-trigger svg {
  stroke: #7AB8CD;
}

@media (min-width: 48em) {
  .body--color-petrol .table__heading:not(.table__heading--sub) button {
    border-top: none;
  }
}

.body--color-petrol .mixitup-control-active {
  background-color: #7AB8CD;
}

.body--color-petrol .pagination .page-numbers {
  background-color: #7AB8CD;
}

.body--color-petrol .pagination .page-numbers:hover, .body--color-petrol .pagination .page-numbers.current {
  background-color: #b2d6e2;
}

.body--color-petrol .table--tabbed .container {
  border-color: #7AB8CD;
}

.body--color-petrol .slick-dots button {
  background-color: #7AB8CD;
}

.body--color-petrol .slick-dots button:hover {
  background-color: white;
}

.body--color-petrol label {
  color: #7AB8CD;
}

.body--color-petrol input, .body--color-petrol textarea {
  border: 1px solid #7AB8CD;
}

.body--color-petrol .article-teaser {
  border-bottom: 1px solid #7AB8CD;
}

.body--color-petrol h1, .body--color-petrol .h1, .body--color-petrol h2, .body--color-petrol .h2, .body--color-petrol h3, .body--color-petrol .h3 {
  color: #7AB8CD;
}

.body--color-petrol .box {
  border-top-color: #7AB8CD;
}

.body--color-orange {
  /*.wave__line:not(.wave__line--orange) {
      display: none;
    }*/
}

.body--color-orange .waves {
  background-image: url("/assets/images/line-orange.svg");
  background-position-y: -2rem;
}

.body--color-orange .logo svg:not(.locked) {
  stroke: #DD9821;
}

.body--color-orange table tbody tr:nth-of-type(odd) {
  background-color: #ebc179;
}

.body--color-orange table tbody tr:nth-of-type(even) {
  background-color: #f2d6a5;
}

.body--color-orange table thead tr {
  background-color: #f8ead2;
}

.body--color-orange a:hover {
  color: #DD9821;
}

.body--color-orange article a,
.body--color-orange .current-menu-item > a {
  color: #DD9821;
}

.body--color-orange button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger),
.body--color-orange .button:not(.button--no-color) {
  border: 1px solid #DD9821;
}

.body--color-orange button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):hover,
.body--color-orange .button:not(.button--no-color):hover {
  background-color: #DD9821;
}

.body--color-orange button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):active,
.body--color-orange .button:not(.button--no-color):active {
  background-color: #c7891e;
}

@media (min-width: 48em) {
  .body--color-orange button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger).control:nth-of-type(1n+2),
  .body--color-orange .button:not(.button--no-color).control:nth-of-type(1n+2) {
    border-left: none;
  }
}

.body--color-orange .current-menu-item .button--dropdown-trigger svg,
.body--color-orange .current_page_item .button--dropdown-trigger svg {
  stroke: #DD9821;
}

@media (min-width: 48em) {
  .body--color-orange .table__heading:not(.table__heading--sub) button {
    border-top: none;
  }
}

.body--color-orange .mixitup-control-active {
  background-color: #DD9821;
}

.body--color-orange .pagination .page-numbers {
  background-color: #DD9821;
}

.body--color-orange .pagination .page-numbers:hover, .body--color-orange .pagination .page-numbers.current {
  background-color: #e8b763;
}

.body--color-orange .table--tabbed .container {
  border-color: #DD9821;
}

.body--color-orange .slick-dots button {
  background-color: #DD9821;
}

.body--color-orange .slick-dots button:hover {
  background-color: #f8ead2;
}

.body--color-orange label {
  color: #DD9821;
}

.body--color-orange input, .body--color-orange textarea {
  border: 1px solid #DD9821;
}

.body--color-orange .article-teaser {
  border-bottom: 1px solid #DD9821;
}

.body--color-orange h1, .body--color-orange .h1, .body--color-orange h2, .body--color-orange .h2, .body--color-orange h3, .body--color-orange .h3 {
  color: #DD9821;
}

.body--color-orange .box {
  border-top-color: #DD9821;
}

.body--color-blue {
  /*.wave__line:not(.wave__line--blue) {
      display: none;
    }*/
}

.body--color-blue .waves {
  background-image: url("/assets/images/line-blue.svg");
  background-position-y: 0;
}

.body--color-blue .logo svg:not(.locked) {
  stroke: #222b5a;
}

.body--color-blue table tbody tr:nth-of-type(odd) {
  background-color: #3e4ea4;
}

.body--color-blue table tbody tr:nth-of-type(even) {
  background-color: #5667bf;
}

.body--color-blue table thead tr {
  background-color: #7b88cd;
}

.body--color-blue a:hover {
  color: #222b5a;
}

.body--color-blue article a,
.body--color-blue .current-menu-item > a {
  color: #222b5a;
}

.body--color-blue button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger),
.body--color-blue .button:not(.button--no-color) {
  border: 1px solid #222b5a;
}

.body--color-blue button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):hover,
.body--color-blue .button:not(.button--no-color):hover {
  background-color: #222b5a;
}

.body--color-blue button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger):active,
.body--color-blue .button:not(.button--no-color):active {
  background-color: #1b2247;
}

@media (min-width: 48em) {
  .body--color-blue button:not(.button--no-color):not(.slick-lightbox-close):not(.slick-arrow):not(.button--dropdown-trigger).control:nth-of-type(1n+2),
  .body--color-blue .button:not(.button--no-color).control:nth-of-type(1n+2) {
    border-left: none;
  }
}

.body--color-blue .current-menu-item .button--dropdown-trigger svg,
.body--color-blue .current_page_item .button--dropdown-trigger svg {
  stroke: #222b5a;
}

@media (min-width: 48em) {
  .body--color-blue .table__heading:not(.table__heading--sub) button {
    border-top: none;
  }
}

.body--color-blue .mixitup-control-active {
  background-color: #222b5a;
}

.body--color-blue .pagination .page-numbers {
  background-color: #222b5a;
}

.body--color-blue .pagination .page-numbers:hover, .body--color-blue .pagination .page-numbers.current {
  background-color: #374692;
}

.body--color-blue .table--tabbed .container {
  border-color: #222b5a;
}

.body--color-blue .slick-dots button {
  background-color: #222b5a;
}

.body--color-blue .slick-dots button:hover {
  background-color: #7b88cd;
}

.body--color-blue label {
  color: #222b5a;
}

.body--color-blue input, .body--color-blue textarea {
  border: 1px solid #222b5a;
}

.body--color-blue .article-teaser {
  border-bottom: 1px solid #222b5a;
}

.body--color-blue h1, .body--color-blue .h1, .body--color-blue h2, .body--color-blue .h2, .body--color-blue h3, .body--color-blue .h3 {
  color: #222b5a;
}

.body--color-blue .box {
  border-top-color: #222b5a;
}

/**
 * Placeholder
 */
/**
 * Mixins
 * [1] DRY on Creating Pseudo Elements
 * [2] Default animation. Arguments can be overridden
 * [3] Vertically center an element. Y-Pos can be varied
 * [4] Horizontally center an block-element
 * [5] remove blur e.g. on images which scale on hover
 */
/**
 * Spacing
 * [1] Function to fetch values from nested maps
 * [2] Set Spacings
 * [3] Mixin to create direction-based responsive spacing
 */
/* [3] */
.bottomSpacing1, main {
  padding-bottom: 60px;
}

@media (min-width: 48em) {
  .bottomSpacing1, main {
    padding-bottom: 90px;
  }
}

@media (min-width: 62em) {
  .bottomSpacing1, main {
    padding-bottom: 100px;
  }
}

@media (min-width: 75em) {
  .bottomSpacing1, main {
    padding-bottom: 130px;
  }
}

.bottomSpacing2 {
  padding-bottom: 50px;
}

@media (min-width: 48em) {
  .bottomSpacing2 {
    padding-bottom: 65px;
  }
}

@media (min-width: 62em) {
  .bottomSpacing2 {
    padding-bottom: 75px;
  }
}

@media (min-width: 75em) {
  .bottomSpacing2 {
    padding-bottom: 110px;
  }
}

.bottomSpacing3 {
  padding-bottom: 40px;
}

@media (min-width: 48em) {
  .bottomSpacing3 {
    padding-bottom: 55px;
  }
}

@media (min-width: 62em) {
  .bottomSpacing3 {
    padding-bottom: 65px;
  }
}

@media (min-width: 75em) {
  .bottomSpacing3 {
    padding-bottom: 90px;
  }
}

.bottomSpacing4 {
  padding-bottom: 20px;
}

@media (min-width: 48em) {
  .bottomSpacing4 {
    padding-bottom: 30px;
  }
}

@media (min-width: 62em) {
  .bottomSpacing4 {
    padding-bottom: 35px;
  }
}

@media (min-width: 75em) {
  .bottomSpacing4 {
    padding-bottom: 40px;
  }
}

.bottomSpacing5 {
  padding-bottom: 24px;
}

@media (min-width: 48em) {
  .bottomSpacing5 {
    padding-bottom: 24px;
  }
}

@media (min-width: 62em) {
  .bottomSpacing5 {
    padding-bottom: 24px;
  }
}

@media (min-width: 75em) {
  .bottomSpacing5 {
    padding-bottom: 30px;
  }
}

/* [3] */
.topSpacing1 {
  padding-top: 60px;
}

@media (min-width: 48em) {
  .topSpacing1 {
    padding-top: 90px;
  }
}

@media (min-width: 62em) {
  .topSpacing1 {
    padding-top: 100px;
  }
}

@media (min-width: 75em) {
  .topSpacing1 {
    padding-top: 130px;
  }
}

.topSpacing2 {
  padding-top: 50px;
}

@media (min-width: 48em) {
  .topSpacing2 {
    padding-top: 65px;
  }
}

@media (min-width: 62em) {
  .topSpacing2 {
    padding-top: 75px;
  }
}

@media (min-width: 75em) {
  .topSpacing2 {
    padding-top: 110px;
  }
}

.topSpacing3 {
  padding-top: 40px;
}

@media (min-width: 48em) {
  .topSpacing3 {
    padding-top: 55px;
  }
}

@media (min-width: 62em) {
  .topSpacing3 {
    padding-top: 65px;
  }
}

@media (min-width: 75em) {
  .topSpacing3 {
    padding-top: 90px;
  }
}

.topSpacing4 {
  padding-top: 20px;
}

@media (min-width: 48em) {
  .topSpacing4 {
    padding-top: 30px;
  }
}

@media (min-width: 62em) {
  .topSpacing4 {
    padding-top: 35px;
  }
}

@media (min-width: 75em) {
  .topSpacing4 {
    padding-top: 40px;
  }
}

.topSpacing5 {
  padding-top: 24px;
}

@media (min-width: 48em) {
  .topSpacing5 {
    padding-top: 24px;
  }
}

@media (min-width: 62em) {
  .topSpacing5 {
    padding-top: 24px;
  }
}

@media (min-width: 75em) {
  .topSpacing5 {
    padding-top: 30px;
  }
}

/**
 * Mobile Top Spacing
 */
@media (max-width: 47.99em) {
  .mobileTopSpacing1 {
    padding-top: 60px;
  }
}

@media (max-width: 47.99em) {
  .mobileTopSpacing2 {
    padding-top: 50px;
  }
}

@media (max-width: 47.99em) {
  .mobileTopSpacing3 {
    padding-top: 40px;
  }
}

@media (max-width: 47.99em) {
  .mobileTopSpacing4 {
    padding-top: 20px;
  }
}

/**
 * Mobile & Tablet Spacing
 */
@media (max-width: 47.99em) {
  .xs-smTopSpacing1 {
    padding-top: 60px;
  }
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .xs-smTopSpacing1 {
    padding-top: 90px;
  }
}

@media (max-width: 47.99em) {
  .xs-smTopSpacing2 {
    padding-top: 50px;
  }
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .xs-smTopSpacing2 {
    padding-top: 65px;
  }
}

@media (max-width: 47.99em) {
  .xs-smTopSpacing3 {
    padding-top: 40px;
  }
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .xs-smTopSpacing3 {
    padding-top: 55px;
  }
}

@media (max-width: 47.99em) {
  .xs-smTopSpacing4 {
    padding-top: 20px;
  }
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .xs-smTopSpacing4 {
    padding-top: 30px;
  }
}

/**
 * Tablet & Desktop Spacing
 */
@media (min-width: 48em) and (max-width: 61.99em) {
  .sm-mdTopSpacing1 {
    padding-top: 90px;
  }
}

@media (min-width: 62em) and (max-width: 74.99em) {
  .sm-mdTopSpacing1 {
    padding-top: 100px;
  }
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .sm-mdTopSpacing2 {
    padding-top: 65px;
  }
}

@media (min-width: 62em) and (max-width: 74.99em) {
  .sm-mdTopSpacing2 {
    padding-top: 75px;
  }
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .sm-mdTopSpacing3 {
    padding-top: 55px;
  }
}

@media (min-width: 62em) and (max-width: 74.99em) {
  .sm-mdTopSpacing3 {
    padding-top: 65px;
  }
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .sm-mdTopSpacing4 {
    padding-top: 30px;
  }
}

@media (min-width: 62em) and (max-width: 74.99em) {
  .sm-mdTopSpacing4 {
    padding-top: 35px;
  }
}

/**
 * Ratio
 */
.ratio4-3:before {
  content: '';
  display: block;
  margin-top: 75%;
}

/**
 * _utilities
*/
.embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed::before {
  display: block;
  content: "";
}

.embed .embed__item,
.embed iframe,
.embed embed,
.embed object,
.embed video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed--16by9:before {
  padding-top: 56.25%;
}

.embed--1by1:before, .slider--gallery .image--thumbnail:before {
  padding-top: 100%;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.row {
  *zoom: 1;
}

.rotate {
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  will-change: transform;
}

.rotate--180 {
  transform: rotate(180deg);
}

.lb-closeContainer {
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  height: 1rem;
  width: 1rem;
}

.lb-closeContainer .lb-close {
  display: block;
  width: 100%;
  height: 100%;
  background-size: 100%;
}

.slick-lightbox-close {
  background: transparent;
}

.lightbox .lb-image {
  border: none;
  border-radius: 0;
}

/**
 * Global
 */
html {
  display: block;
  height: 100%;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  display: block;
  position: relative;
  min-height: 100%;
  font: normal 1.6rem/1.4375 "Archer Book", sans-serif;
  color: #033c57;
}

.row {
  position: relative;
}

.waves {
  position: relative;
  height: 15rem;
  background: transparent url(/assets/images/lines-all.svg) repeat-x -15.5rem 0/cover;
}

@media (min-width: 48em) {
  .waves {
    height: 17rem;
  }
}

.waves__link {
  position: absolute;
  left: 7.8rem;
  bottom: 2rem;
  text-decoration: none;
}

@media (min-width: 48em) {
  .waves__link {
    bottom: 2.5rem;
  }
}

.page-wrap {
  position: relative;
}

.page-wrap__main,
header {
  max-width: 1200px;
}

.page-wrap__main:before, .page-wrap__main:after,
header:before,
header:after {
  content: " ";
  display: table;
}

.page-wrap__main:after,
header:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.page-wrap__main,
header {
  *zoom: 1;
}

@media (min-width: 48em) {
  .page-wrap__main,
  header {
    padding-left: 5.5rem;
  }
}

@media (min-width: 62em) {
  .page-wrap__main--netzwerk {
    background: transparent url(/assets/images/lines.svg) repeat-x;
  }
}

aside {
  padding: 0 1.5rem;
}

@media (min-width: 48em) {
  aside {
    width: calc(100% - 62%);
    float: left;
    padding-right: 4rem;
  }
}

@media (min-width: 75em) {
  aside {
    width: calc(100% - 69%);
  }
}

.lines {
  display: none;
}

@media (min-width: 62em) {
  .lines {
    z-index: -1;
    display: block;
    position: absolute;
    top: 26rem;
    left: 0;
    height: 80rem;
    width: 100%;
    background: url(/assets/images/lines.svg) repeat-x -30rem 2rem/auto 60rem;
  }
}

main {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 48em) {
  main {
    padding-left: 4rem;
    width: 62%;
    float: left;
    max-width: 60rem;
  }
}

@media (min-width: 75em) {
  main {
    width: 69%;
  }
}

.container {
  overflow: hidden;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.container {
  *zoom: 1;
}

.table--tabbed .container {
  border: 1px solid #222b5a;
  border-top: none;
  margin-top: 0;
  padding: 2.4rem 0 2rem;
}

/**
 * Typography
 */
@font-face {
  font-family: 'Archer Book';
  src: url("../fonts/Archer-Book.eot");
  src: url("../fonts/Archer-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/Archer-Book.woff") format("woff"), url("../fonts/Archer-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Archer';
  src: url("../fonts/Archer-Light.eot");
  src: url("../fonts/Archer-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Archer-Light.woff") format("woff"), url("../fonts/Archer-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Archer';
  src: url("../fonts/Archer-Bold.eot");
  src: url("../fonts/Archer-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Archer-Bold.woff") format("woff"), url("../fonts/Archer-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

h1, .h1, h2, .h2, h3, .h3 {
  color: #222b5a;
  font-family: "Archer";
  font-weight: bold;
}

h1, .h1 {
  font-size: 2.4rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.6rem;
}

article * + * {
  margin-top: 1.2rem;
}

@media (min-width: 62em) {
  article * + * {
    margin-top: 2rem;
  }
}

ul {
  list-style: circle;
  padding-left: 2rem;
}

li + li {
  margin-top: .2rem;
}

li ul {
  padding-left: 3rem;
  margin-top: .8rem;
}

b, strong {
  font-family: "Archer";
  font-weight: bold;
}

em {
  font-style: italic;
}

/**
 * Links
 */
a {
  color: #222b5a;
}

a[href^='http']:after {
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  /* [1] */
  content: "";
  display: inline-block;
  position: relative;
  opacity: .5;
  top: -.3rem;
  height: 1rem;
  width: 1rem;
  background: transparent no-repeat 50% 50% / 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M5 3c-1.102 0-2 .898-2 2v14c0 1.102.898 2 2 2h14c1.102 0 2-.898 2-2v-6l-2-2v8H5V5h8l-2-2zm9 0l2.656 2.656-7.5 7.5 1.688 1.688 7.5-7.5L21 10V3z'/%3E%3C/svg%3E");
}

a[href^='http'].no-external:after,
aside a[href^='http']:after {
  display: none;
}

a[href^='http']:hover:after {
  opacity: 1;
}

a:hover {
  text-decoration: none;
}

.read-more a,
.menu__item a {
  text-decoration: none;
}

a[data-lightbox] {
  display: block;
}

button,
.button {
  cursor: pointer;
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  background-color: transparent;
  border: 1px solid #222b5a;
  border-radius: 0;
  padding: .5rem 1.6rem;
  font-size: 1.6rem;
  line-height: 2.3rem;
  width: 100%;
  display: block;
  max-width: 30rem;
}

button svg,
.button svg {
  width: 100%;
  height: 100%;
}

button:visited,
.button:visited {
  color: #000;
}

button:hover,
.button:hover {
  background-color: #222b5a;
  color: #fff;
}

button:active,
.button:active {
  background-color: #1b2247;
}

.button--dropdown-trigger {
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  background-color: transparent;
  padding: .4rem .7rem .5rem;
  /* border: 1px solid $color-grey; */
  border: none;
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 0;
  right: .8rem;
  z-index: 10;
  border-radius: 0;
}

.button--dropdown-trigger svg {
  transform-origin: center center;
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  stroke: #222b5a;
}

@media (max-width: 74.99em) {
  .button--dropdown-trigger[aria-expanded="true"] {
    padding-top: .3rem;
  }
}

.button--dropdown-trigger[aria-expanded="true"] svg {
  transform: rotateZ(180deg);
}

.button--dropdown-trigger:focus {
  outline: none;
}

@media (min-width: 48em) {
  .button--dropdown-trigger {
    position: relative;
    right: initial;
    top: .6rem;
    border: none;
    width: 1rem;
    height: 2rem;
    padding: 0;
    display: inline;
  }
  .button--dropdown-trigger[aria-expanded="true"] {
    background-color: transparent;
  }
}

.button--dropdown-trigger:hover {
  background-color: transparent;
}

.button--download {
  position: relative;
}

.button--download:before, .button--download:after {
  /* [1] */
  content: "";
  display: block;
  position: absolute;
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent no-repeat 1rem 50% / 2.4rem 2.4rem;
}

.button--download:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M15 4v16.563L9.72 15.28l-1.44 1.44 7 7 .72.686.72-.687 7-7-1.44-1.44L17 20.564V4zM7 26v2h18v-2z'/%3E%3C/svg%3E");
}

.button--download:after {
  opacity: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M15 4v16.563L9.72 15.28l-1.44 1.44 7 7 .72.686.72-.687 7-7-1.44-1.44L17 20.564V4zM7 26v2h18v-2z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.button--download:hover:before {
  opacity: 0;
}

.button--download:hover:after {
  opacity: 1;
}

/**
 * _image
*/
.image {
  max-width: 100%;
  height: auto;
}

@media (min-width: 48em) {
  .image--portrait {
    max-width: 30rem;
  }
}

.image--full {
  width: 100%;
  height: auto;
}

.image--thumbnail {
  width: 100%;
  height: 20rem;
  background: transparent no-repeat 50% 50% / cover;
}

@media (min-width: 48em) {
  .image--thumbnail {
    height: 15rem;
    width: 15rem;
  }
}

.gallery .image--thumbnail {
  width: 100%;
  height: auto;
}

/**
 * _table
*/
table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}

table thead th {
  text-align: left;
  vertical-align: bottom;
}

table td,
table th {
  padding: .75em;
}

table tbody tr:nth-of-type(odd) {
  background-color: #c5cbe9;
}

table tbody tr:nth-of-type(even) {
  background-color: #eaecf7;
}

table thead tr {
  background-color: #a0a9db;
}

.table--comparison tr {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.table--comparison td, .table--comparison th {
  display: block;
  width: 33.3333333333%;
}

.table--comparison td:first-child, .table--comparison th:first-child {
  width: 100%;
}

.table--comparison th:first-child {
  display: none;
}

/**
 * MixItUp Styles for Tabbed Table
 */
@media (min-width: 48em) {
  .controls {
    display: flex;
    flex-wrap: wrap;
  }
}

.controls__group {
  overflow: hidden;
}

.controls__group:before, .controls__group:after {
  content: " ";
  display: table;
}

.controls__group:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.controls__group {
  *zoom: 1;
}

.controls__group button {
  max-width: none;
  width: 100%;
  position: relative;
}

.controls__group button:focus {
  outline: none;
}

.controls__group > button {
  position: relative;
}

.controls__group > button svg {
  position: absolute;
  top: .8rem;
  right: 1rem;
  margin-top: 0;
  width: 1.8rem;
  height: 1.8rem;
}

@media (min-width: 48em) {
  .controls__group {
    flex: 1 100%;
  }
  .controls__group > button {
    display: none;
  }
  .controls__group:first-child {
    order: 2;
  }
}

.table__heading {
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  position: relative;
  max-height: 0;
}

.table__heading--is-visible {
  max-height: 500px;
}

@media (min-width: 48em) {
  .table__heading {
    max-height: initial;
    margin-top: 0;
  }
  .table__heading:before, .table__heading:after {
    content: " ";
    display: table;
  }
  .table__heading:after {
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  .table__heading {
    *zoom: 1;
  }
  .table__heading button {
    margin-top: 0;
    float: left;
    pointer-events: none;
    border-top: none;
    width: 33.3333333333%;
  }
  .table__heading button:hover {
    cursor: not-allowed;
  }
  .table__heading button:nth-of-type(1n+2) {
    border-left: none;
  }
}

.mixitup-control-active {
  background-color: #222b5a;
  color: #fff;
}

@media (min-width: 48em) {
  .table__heading--sub button {
    border-top: 1px solid #222b5a;
    pointer-events: all;
    width: 50%;
  }
  .table__heading--sub button:hover {
    cursor: pointer;
  }
}

@media (min-width: 48em) {
  .table__content {
    margin-top: 0;
    width: 33.3333333333%;
    float: left;
  }
}

/**
 * _slider
*/
.slider {
  overflow: hidden;
}

.slick-slide + .slick-slide {
  margin-top: 0;
}

.slider--gallery .image--thumbnail {
  display: block;
  width: 100%;
  height: auto;
}

.slider--gallery .image--thumbnail:before {
  content: '';
  display: block;
}

.slider--gallery .slick-slide {
  margin: 0 .2rem;
}

.slider--gallery .slick-list {
  margin: 0 -.2rem;
}

/**
 * Slider Controls
 */
.slider__arrow, .slick-lightbox .slick-arrow {
  position: absolute;
  left: -1.2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  height: 100%;
  padding: 0;
  border: none;
  z-index: 10;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.42 75'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %233a3a3a; %7D %3C/style%3E%3C/defs%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cpath id='Ebene_1-2' d='M37.5 0L0 37.5 37.5 75h19.92l-37.5-37.5L57.42 0H37.5z' class='cls-1' data-name='Ebene 1'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 center/2.4rem 3.2rem;
  opacity: 0;
  transition: 0.25s cubic-bezier(0.5, 0.9, 0, 1);
  margin-top: 0;
}

.slider--default .slider__arrow, .slider--default .slick-lightbox .slick-arrow, .slick-lightbox .slider--default .slick-arrow {
  top: 40%;
  height: 95%;
}

@media (min-width: 48em) {
  .slider--default .slider__arrow, .slider--default .slick-lightbox .slick-arrow, .slick-lightbox .slider--default .slick-arrow {
    top: 45%;
  }
}

.slider--gallery .slider__arrow, .slider--gallery .slick-lightbox .slick-arrow, .slick-lightbox .slider--gallery .slick-arrow {
  width: 2.4rem;
}

.slider__arrow:focus, .slick-lightbox .slick-arrow:focus {
  outline: none;
}

.slider:hover .slider__arrow, .slider:hover .slick-lightbox .slick-arrow, .slick-lightbox .slider:hover .slick-arrow {
  opacity: .7;
  left: 3rem;
}

.slider:hover .slider__arrow:hover, .slider:hover .slick-lightbox .slick-arrow:hover, .slick-lightbox .slider:hover .slick-arrow:hover {
  opacity: 1;
  background-color: transparent;
}

.slider--article .slider__arrow, .slider--article .slick-lightbox .slick-arrow, .slick-lightbox .slider--article .slick-arrow {
  height: 100%;
  width: 50%;
  background-position-x: 3rem;
}

.slider--article .slider__arrow--next, .slider--article .slick-lightbox .slick-next, .slick-lightbox .slider--article .slick-next {
  background-position-x: calc(100% - 3rem);
}

.slider--article:hover .slider__arrow, .slider--article:hover .slick-lightbox .slick-arrow, .slick-lightbox .slider--article:hover .slick-arrow {
  left: 0;
}

.slider__arrow--next, .slick-lightbox .slick-next {
  left: auto;
  right: -2.4rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.42 75'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %233a3a3a; %7D %3C/style%3E%3C/defs%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cpath id='Ebene_1-2' d='M19.92 0l37.5 37.5L19.92 75H0l37.5-37.5L0 0h19.92z' class='cls-1' data-name='Ebene 1'/%3E%3C/g%3E%3C/svg%3E");
  background-position-x: 100%;
}

.slider:hover .slider__arrow--next, .slider:hover .slick-lightbox .slick-next, .slick-lightbox .slider:hover .slick-next {
  left: auto;
  right: 3rem;
}

.slider--article:hover .slider__arrow--next, .slider--article:hover .slick-lightbox .slick-next, .slick-lightbox .slider--article:hover .slick-next {
  right: 0;
  left: auto;
}

.slick-lightbox .slick-arrow {
  z-index: 999999999;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;
  background-color: #fff;
  background-size: 50%;
  background-position-x: 45%;
  left: 1.5rem;
}

.slick-lightbox .slick-next {
  right: 1.5rem;
  left: initial;
  background-position-x: 55%;
}

@media (max-width: 47.99em) {
  .slider__arrow, .slick-lightbox .slick-arrow {
    opacity: 1;
    left: 3rem;
  }
  .slider__arrow--next, .slick-lightbox .slick-next {
    right: 3rem;
    left: auto;
  }
}

.slick-dots {
  padding: 0;
  margin: 0 auto;
  z-index: 10;
  list-style: none;
  text-align: center;
}

.slick-dots:before, .slick-dots:after {
  content: " ";
  display: table;
}

.slick-dots:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.slick-dots {
  *zoom: 1;
}

@media (max-width: 47.99em) {
  .slick-dots {
    background-color: #fff;
    margin-bottom: 2rem;
  }
  .slider--with-caption .slick-dots {
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    background-color: transparent;
    right: 0;
    left: 0;
  }
}

.slick-dots li {
  vertical-align: middle;
  display: inline-block;
  margin-right: 1rem;
  width: 8px;
  height: 8px;
}

.slick-dots li + li {
  margin-top: 0;
}

.slick-dots button {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 100%;
  border: none;
  outline: none;
  background-color: #222b5a;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  transform: scale(0.5);
  transform-origin: 50% 50%;
  transition: 0.25s cubic-bezier(0.5, 0.9, 0, 1);
}

.slick-dots button:hover {
  background-color: #7b88cd;
  transform: scale(1);
}

.slick-dots .slick-active button {
  transform: scale(1);
}

.slider--testimonials .slick-dots {
  position: relative;
  bottom: initial;
}

/**
 * _form
*/
fieldset:before, fieldset:after {
  content: " ";
  display: table;
}

fieldset:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

fieldset {
  *zoom: 1;
}

fieldset div {
  float: left;
  width: 100%;
  position: relative;
}

fieldset div + div {
  margin-top: 2rem;
}

@media (min-width: 48em) {
  fieldset div:nth-child(3), fieldset div:nth-child(4) {
    width: 50%;
    float: left;
  }
  fieldset div:nth-child(4) {
    width: calc(50% - 15px);
    margin-left: 15px;
  }
}

label {
  display: block;
  font-family: "Archer";
  color: #222b5a;
  font-weight: bold;
  background-color: #fff;
  position: absolute;
  top: -1.2rem;
  left: 1rem;
}

input, textarea {
  width: 100%;
  padding: 1.2rem 1rem 1rem;
  border: 1px solid #222b5a;
  border-radius: 0;
  box-shadow: none;
}

label + input, label + textarea {
  margin-top: 0;
}

textarea {
  min-height: 15rem;
}

/**
 * _article
*/
article:before, article:after {
  content: " ";
  display: table;
}

article:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

article {
  *zoom: 1;
}

.article-teaser {
  border-bottom: 1px solid #222b5a;
  padding-bottom: 2rem;
}

.article-teaser:before, .article-teaser:after {
  content: " ";
  display: table;
}

.article-teaser:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.article-teaser {
  *zoom: 1;
}

.article-teaser + .article-teaser {
  margin-top: 3rem;
}

@media (min-width: 48em) {
  .article-teaser {
    margin-top: 6rem;
  }
}

.read-more {
  margin-left: 0.1rem;
}

@media (min-width: 48em) {
  .article-teaser__image {
    float: right;
  }
}

.article-teaser__image + .article-teaser__content {
  margin-top: 0;
}

@media (min-width: 48em) {
  .article-teaser__content {
    float: left;
    width: calc(100% - 18rem);
  }
}

@media (max-width: 47.99em) {
  .article-teaser__content h3 {
    margin-top: 1.2rem;
  }
}

/**
 * _box
*/
@media (min-width: 62em) {
  .box--brinkhoff {
    left: 5rem;
    top: 10rem;
  }
  .box--west-leuer {
    left: 20rem;
    top: 11rem;
  }
  .box--supervision {
    left: 35rem;
    top: 11rem;
  }
  .box--kubach {
    left: 32rem;
    top: 33rem;
  }
  .box--vehreschild {
    left: 17rem;
    top: 47rem;
  }
  .box--tress {
    left: 13rem;
    top: 23rem;
  }
  .box--vogel {
    left: -4rem;
    top: 43.5rem;
  }
  .box--tangen-petraitis {
    left: -16rem;
    top: 43rem;
  }
  .box--epc {
    left: -22rem;
    top: 33rem;
  }
  .box--offermanns {
    left: -5rem;
    top: 20rem;
  }
  .box--brouwers {
    left: -20rem;
    top: 22rem;
  }
  .box--loewer-hirsch {
    left: -11rem;
    top: 6rem;
  }
  .box--pop {
    left: 35rem;
    top: -6rem;
  }
  .box--ippp {
    left: 20rem;
    top: -6rem;
  }
  .box--doerte {
    left: 5rem;
    top: -0.5rem;
  }
  .box--henning {
    left: -15rem;
    top: -3rem;
  }
  .box--urban {
    left: -27rem;
    top: 47rem;
  }
  .box--dauth {
    left: 43rem;
    top: 29rem;
  }
}

@media (min-width: 75em) {
  .box--brinkhoff {
    left: 27rem;
    top: 12rem;
  }
  .box--west-leuer {
    left: 40rem;
    top: 14rem;
  }
  .box--supervision {
    left: 60rem;
    top: 14rem;
  }
  .box--kubach {
    left: 47rem;
    top: 38rem;
  }
  .box--vehreschild {
    left: 16rem;
    top: 47rem;
  }
  .box--tress {
    left: 24rem;
    top: 30rem;
  }
  .box--vogel {
    left: 1rem;
    top: 48rem;
  }
  .box--tangen-petraitis {
    left: -10rem;
    top: 37rem;
  }
  .box--epc {
    left: 12rem;
    top: 9rem;
  }
  .box--offermanns {
    left: 10rem;
    top: 23rem;
  }
  .box--brouwers {
    left: -11rem;
    top: 23rem;
  }
  .box--loewer-hirsch {
    left: -8rem;
    top: -1rem;
  }
  .box--pop {
    left: 54rem;
    top: -1rem;
  }
  .box--ippp {
    left: 38rem;
    top: -5rem;
  }
  .box--doerte {
    left: 22rem;
    top: -1rem;
  }
  .box--henning {
    left: 5rem;
    top: -3rem;
  }
  .box--urban {
    left: -20rem;
    top: 48rem;
  }
  .box--dauth {
    left: 61rem;
    top: 31rem;
  }
}

.box {
  width: 100%;
  border: 1px solid #ccc;
  border-top: 0.4rem solid #222b5a;
  padding: 1.5rem 1rem 1.3rem;
}

.box:before, .box:after {
  content: " ";
  display: table;
}

.box:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.box {
  *zoom: 1;
}

@media (min-width: 62em) {
  .box {
    text-align: center;
    border: none;
    max-width: 14rem;
    position: absolute;
  }
  .box:hover {
    z-index: 99;
  }
}

.box__title {
  font-family: "Archer";
  font-weight: bold;
}

.box__image {
  float: left;
  background: transparent no-repeat 50% 50% / cover;
  height: 5rem;
  width: 5rem;
}

.box--rounded .box__image {
  border-radius: 50%;
  height: 6.5rem;
  width: 6.5rem;
}

@media (min-width: 62em) {
  .box__image {
    display: block;
    margin: 0 auto;
  }
}

.box__content {
  margin-top: 0;
  float: left;
  margin-left: 2rem;
  width: calc(100% - 6.5rem - 2rem);
}

@media (min-width: 62em) {
  .box__content {
    width: 100%;
    margin-left: 0;
    margin-top: .8rem;
    font-size: 1.2rem;
  }
}

.box__image,
.box__content {
  position: relative;
}

@media (min-width: 62em) {
  .box__image,
  .box__content {
    float: none;
  }
}

/**
 * _gallery
*/
.gallery:before, .gallery:after {
  content: " ";
  display: table;
}

.gallery:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.gallery {
  *zoom: 1;
}

@media (min-width: 48em) {
  .gallery {
    margin-left: -.2rem;
    margin-right: -.2rem;
  }
}

.gallery__item {
  float: left;
  width: calc(50% - .5rem);
  margin-right: .5rem;
}

.gallery__item + .gallery__item {
  margin-top: 0;
}

@media (max-width: 47.99em) {
  .gallery__item:nth-child(2n+2) {
    margin-left: .5rem;
    margin-right: 0;
  }
  .gallery__item:nth-child(1n+3) {
    margin-top: 1rem;
  }
}

@media (min-width: 48em) {
  .gallery__item {
    width: calc(33.3333333333% - .4rem);
    margin: 0 .2rem;
    /*&:nth-child(1n+2) {
      margin-left: .4rem;
    }
    &:nth-child(3n+1) {
      margin-left: 0;
    }
    &:nth-child(1n+4) {
      margin-top: .4rem;
    }*/
  }
  .gallery__item:nth-child(1n+4) {
    margin-top: .4rem;
  }
}

/**
 * _pagination
*/
.pagination {
  margin-top: 4.8rem;
  width: 100%;
  text-align: right;
}

.pagination:before, .pagination:after {
  content: " ";
  display: table;
}

.pagination:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.pagination {
  *zoom: 1;
}

.pagination .page-numbers {
  margin-top: 0;
  padding: .8rem 1.2rem;
  color: #fff;
  text-decoration: none;
  background-color: #222b5a;
}

.pagination .page-numbers + .page-numbers {
  margin-top: 0;
  margin-left: .4rem;
}

.pagination .page-numbers:hover, .pagination .page-numbers.current {
  background-color: #374692;
}

/**
 * Header
 */
header {
  padding: 2rem 1.5rem 0;
  display: block;
  position: relative;
}

@media (min-width: 48em) {
  header {
    margin-top: 1rem;
    padding: 0 0 0 5.5rem;
  }
}

.navigation {
  position: relative;
  display: block;
  max-width: 100%;
}

.navigation:before, .navigation:after {
  content: " ";
  display: table;
}

.navigation:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.navigation {
  *zoom: 1;
}

.logo {
  display: block;
  width: 10rem;
  margin: 0 auto;
  text-decoration: none;
  text-align: center;
}

.logo:before, .logo:after {
  content: " ";
  display: table;
}

.logo:after {
  display: block;
  clear: both;
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
}

.logo {
  *zoom: 1;
}

.logo svg {
  display: block;
  width: 6.4rem;
  height: 8rem;
  margin: 0 auto;
  stroke: #222b5a;
}

@media (min-width: 48em) {
  .logo {
    position: relative;
    width: 15rem;
    margin: 0;
    left: calc(38% + 2rem);
  }
  .logo svg {
    width: 10rem;
    height: 12.5rem;
  }
}

@media (min-width: 75em) {
  .logo {
    left: calc(31% + 2rem);
  }
}

.logo__text {
  color: #222b5a;
  text-decoration: none;
  font-family: "Archer";
  font-weight: 300;
}

.logo__text:first-of-type {
  font-family: "Archer Book";
}

@media (min-width: 48em) {
  .logo__text {
    font-size: 2.2rem;
  }
}

/**
 * Menu
 */
.menu {
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  position: relative;
  display: block;
  width: 100%;
  list-style: none;
  padding: 0 .8rem;
  margin: 0;
  font-family: "Archer";
  font-weight: 300;
}

.menu[aria-expanded="true"] {
  visibility: visible;
  max-height: 80vh;
  padding: 1rem .8rem .8rem;
  border: 1px solid #ccc;
}

@media (max-width: 47.99em) {
  .menu {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    border: 1px solid transparent;
  }
}

@media (max-width: 47.99em) {
  .menu--main {
    width: 100%;
  }
  .menu--main[aria-expanded="true"] {
    margin: 2rem 0;
    overflow-y: auto;
    background-color: #fff;
  }
}

@media (min-width: 48em) and (max-width: 47.99em) {
  .menu--main {
    position: absolute;
    max-width: 250px;
    top: 6rem;
    right: 2.2rem;
  }
}

@media (min-width: 48em) {
  .menu--main {
    width: initial;
    padding: 0;
  }
}

.menu__item {
  display: block;
  position: relative;
  line-height: 1;
}

.menu__item a {
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  color: #222b5a;
  display: block;
  padding: .8rem;
}

.menu__item.current-menu-item > a {
  text-decoration: none;
}

.menu__item + .menu__item {
  margin-top: 0;
}

@media (min-width: 48em) {
  .menu--main > .menu__item {
    display: block;
    padding: 0;
    line-height: 1.11111111;
  }
  .menu--main > .menu__item a {
    display: inline-block;
  }
  .menu__item:last-child a {
    padding-right: 0;
  }
}

.menu--sub {
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  border: none;
  padding: 0 2rem 0;
}

.menu--sub[aria-expanded="true"] {
  border: none;
  padding: 0 2rem .8rem;
}

@media (min-width: 48em) {
  .menu--sub {
    transform: translateY(-10%);
    visibility: hidden;
    opacity: 0;
    max-height: 1px;
    overflow: hidden;
    display: block;
    padding: 0 1rem 0;
  }
  .menu--sub .menu__item {
    float: none;
    display: block;
    margin: 0 0 0 2rem;
  }
  .menu--sub .menu__item a {
    display: inline-block;
  }
  .menu--sub[aria-expanded="true"],
  .current-menu-parent .menu--sub,
  .current-menu-item.menu-item-has-children .menu--sub {
    padding: 0 1rem 0;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    max-height: 80vh;
  }
}

/**
 * Menu Bar
 */
.navigation > button {
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  top: 2.2rem;
  position: absolute;
  padding: 0;
  right: 0;
  width: 2.4rem;
  height: 2.4rem;
  background-color: transparent;
  border: none;
}

@media (min-width: 48em) {
  .navigation > button {
    display: none;
  }
}

.navigation > button:hover {
  background-color: transparent;
}

.menu-bar {
  /* [2] */
  transition: 0.3s ease;
  transition-property: all;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  margin: 6px auto;
}

button[aria-expanded="true"] .menu-bar {
  transform-origin: 0 100%;
}

button[aria-expanded="true"] .menu-bar:first-child {
  transform: rotate(45deg) translateY(-5px);
}

button[aria-expanded="true"] .menu-bar:nth-child(2) {
  opacity: 0;
}

button[aria-expanded="true"] .menu-bar:last-child {
  transform: rotate(-45deg) translateY(2px) translateX(5px);
}
