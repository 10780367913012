/**
 * _form
*/

form {

}

fieldset {
  @include clearfix();
}

fieldset div {
  float: left;
  width: 100%;
  position: relative;

  + div {
    margin-top: 2rem;
  }


  @include mq($from: $navCollapseOn) {
    &:nth-child(3),
    &:nth-child(4) {
      width: 50%;
      float: left;
    }

    &:nth-child(4) {
      width: calc(50% - 15px);
      margin-left: 15px;
    }
  }
}

label {
  display: block;
  font-family: $font-primary;
  color: $color-blue;
  font-weight: bold;
  background-color: #fff;
  position: absolute;
  top: -1.2rem;
  left: 1rem;
}

input, textarea {
  width: 100%;
  padding: 1.2rem 1rem 1rem;
  border: 1px solid $color-blue;
  border-radius: 0;
  box-shadow: none;

  label + & {
    margin-top: 0;
  }
}

textarea {
  min-height: 15rem;
}

