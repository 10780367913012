/**
 * Mixins
 * [1] DRY on Creating Pseudo Elements
 * [2] Default animation. Arguments can be overridden
 * [3] Vertically center an element. Y-Pos can be varied
 * [4] Horizontally center an block-element
 * [5] remove blur e.g. on images which scale on hover
 */

@mixin pseudo($display: block, $pos: absolute, $content: '') { /* [1] */
  content: $content;
  display: $display;
  position: $pos;
}

@mixin anim($prop: all, $dur: .3s, $timing: ease ) { /* [2] */
  transition: $dur $timing;
  transition-property: $prop;
}

@mixin centerY($pos: absolute, $offset: 50%) { /* [3] */
  position: $pos;
  top: $offset;
  transform: translateY(calc(-1 * #{$offset}))
}

@mixin centerX() { /* [4] */
  margin: 0 auto;
  left: 0;
  right: 0;
}

@mixin removeBlur() { /* [5] */
  backface-visibility: hidden;
  transform: translateZ(0);
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  & {
    *zoom: 1;
  }
}
