/**
 * _utilities
*/

.embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed__item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed--16by9 {
  &:before {
    padding-top: percentage(9 / 16);
  }
}

.embed--1by1 {
  &:before {
    padding-top: percentage(1 / 1);
  }
}

.row {
  @include clearfix();
}

.rotate {
  @include anim();
  will-change: transform;

  &--180 {
    transform: rotate(180deg);
  }
}


.lb-closeContainer {
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  height: 1rem;
  width: 1rem;
}

.lb-closeContainer .lb-close {
  display: block;
  width: 100%;
  height: 100%;
  background-size: 100%;
}

.slick-lightbox-close {
  background: transparent;
}

.lightbox .lb-image {
  border: none;
  border-radius: 0;
}
